import styled from 'styled-components';
import { fuentePrincipal, fuenteTitulos } from '../variables';

export const TagsContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  font-family: ${fuentePrincipal};
  justify-content: center;
  margin-top: 4em;
  & li{
      flex: 1 325px;
      font-weight: bold;
      font-family: ${fuenteTitulos};
      margin-bottom: 4em;
      display: flex;
      align-items: center;
      justify-content: center;
      & span{
          background: #C0E8F0;
          width: 60px;
          height: 60px;
          font-size: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          border-radius: 50%;
          margin-right: .5em;
      }
      & strong{
          color: black;
          width: 200px;
      }
  }
`;