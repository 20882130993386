import * as React from "react"

import Seo from "../components/seo"
import Hero from "../components/Hero"
import ServiceText from "../components/ServiceText"
import { usePages } from "../hooks/usePages"

const OrganizationalCulture = ({ pageContext }) => {
  const { name } = pageContext

  const page = usePages(name)

  const info = page.Cuerpo[0]

  return (
    <>
      <Seo title={info.Title} />
      <Hero img={"organizational-culture.png"} title={info.Title} />
      <ServiceText text={info.Description} tags={info.ListItems} />
    </>
  )
}

export default OrganizationalCulture
