import React from "react"
import { Button, Container } from "../styles"
import { FaBriefcase } from "react-icons/fa"
import { TagsContainer } from "../styles/components/ServiceText"
import { css } from "styled-components"
import { useLang } from "../hooks/useLang"
import { useTranslate } from "react-translate"
import { useItems } from "../hooks/useItems"

export default function ServiceText({ tags, text }) {
  const [lang, setLang] = useLang("en")
  const buttonText = useItems(
    lang === "en" ? "Button English" : "Button Spanish"
  ).content[0].text

  return (
    <Container>
      <p
        css={css`
          font-size: 1.3rem;

          @media (max-width: 500px) {
            font-size: 1rem;
          }
        `}
      >
        {text}
      </p>
      <TagsContainer>
        {tags.map(({ Name }, index) => {
          const tagId = index + Name.toLowerCase().split(" ").join("-")
          return (
            <li key={tagId}>
              <span>
                <FaBriefcase />
              </span>
              <strong>{Name}</strong>
            </li>
          )
        })}
      </TagsContainer>
      <Button to="/contact" big={true}>
        {buttonText}
      </Button>
    </Container>
  )
}
